import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import ImageFromNewt from "../ImageFromNewt"
import BlackButton from "../button/BlackButton"
const defaultImage = "../../../images/favicons/ogp-image.jpg"

export default function SpecialFeature() {
  const specialFeatureQuery = useStaticQuery(graphql`
    {
      allNewtSpecialFeature {
        edges {
          node {
            specialFeatureName
            coverImage {
              src
            }
          }
        }
      }
    }
  `)

  function defaultImageOrFromNewt(cover){
    if(cover.coverImage != null){
      return(
        <ImageFromNewt
                  src={cover.coverImage.src}
                  className={" object-cover"}
                />
      )
    }else{
      <StaticImage
        src={defaultImage}
        layout="constrained"
        alt="ohmycottie"
      />
    }
  }
  return (
    <div className="basic-wrapper pb-16 pt-8">
      <div className="grid md:grid-cols-4 gap-8 mb-16">
        {specialFeatureQuery.allNewtSpecialFeature.edges.map((edge, index) => {
          return (
            <div className="link-image-wrapper" key={index}>
              <Link
                to={`/special-feature/` + edge.node.specialFeatureName}
                className="block aspect-[2.2/1] overflow-hidden rounded-lg mb-4"
              >
                { defaultImageOrFromNewt(edge.node) }
              </Link>
              <h3 className="leading-normal">{edge.node.specialFeatureName}</h3>
            </div>
          )
        })}
      </div>
      <div className="grid">
        <BlackButton
          text={"特集一覧"}
          link={"/special-feature"}
          className={"m-auto md:w-1/3"}
        />
      </div>
    </div>
  )
}
