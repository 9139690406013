import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import Header from "../components/Layout/Header"
import Card from "../components/Atomic/Post/Card"
import MarkerHead from "../components/Atomic/Typography/MarkerHead"
import SpecialFeature from "../components/Atomic/Post/SpecialFeature"
import Pagination from "../components/Atomic/Pagination"
import Seo from "../components/Layout/Seo"
import GoldenArea from "../components/Atomic/Post/GoldenArea"
// import AdsCard from "../components/Adsense"

export default function Archive({ data, pageContext }) {

  return (
    <div>
      <Layout>
        <Header />
        <div>
          <MarkerHead text={"Recipes"} />
          <section className="w-full bg-white py-16 mb-16">
            <div className="basic-wrapper grid gap-6 md:grid-cols-3 xl:grid-cols-4">
              {data.allNewtRecipe.edges.map((edge, index) => {
                const recipe = edge.node
                const taglist = recipe.categoryTags.tags.map(tag => {
                  return tag.tagName
                })
                const cook = recipe.recipeMeta.cookTime.replace(/[^0-9 ]/g, "")
                const prep = recipe.recipeMeta.prepTime.replace(/[^0-9 ]/g, "")
                const sum = parseInt(cook) + parseInt(prep)
                const totalTime =
                  sum > 59
                    ? Math.floor((sum / 60) * 10) / 10 + " hour"
                    : sum + " min."
                return (
                  <>
                    <Card
                      title={recipe.recipeMeta.recipeName}
                      img={recipe.eyecatch.src}
                      tags={taglist}
                      time={totalTime}
                      link={recipe.slug}
                      hasVideo={recipe.youtubeLink ? true : false}
                      key={index}
                    />
                  </>
                )
              })}
            </div>
            
          </section>
        </div>
        <Pagination
          totalCount={data.allNewtRecipe.totalCount}
          current={pageContext.current}
        />
        <GoldenArea />

        <MarkerHead text={"Special Feature"} />

        <section>
          <SpecialFeature />
        </section>
      </Layout>
    </div>
  )
}

export const query = graphql`
  query ($limit: Int!, $skip: Int!) {
    allNewtRecipe(limit: $limit, skip: $skip, sort: {fields: releadedDate, order: DESC}) {
      edges {
        node {
          categoryTags {
            tags {
              tagName
            }
          }
          recipeTitle
          eyecatch {
            src
          }
          slug
          recipeMeta {
            prepTime
            cookTime
            recipeName
          }
          youtubeLink
          releadedDate
        }
      }
      totalCount
    }
  }
`

export const Head = () => <Seo title="レシピ一覧" />
